import React, { Component } from "react"; 
import './Login.css'

class Login extends Component{
    constructor(props){
        super(props);
        this.state ={
            email:'',
            password:'',
            user:'',
            loginError: false,
        }
        this.login = this.login.bind(this);
    }
    login(event){
        console.log(this.state);
        let eml ="admin@metarealm.com";
        let paswd ="123456";
        if(this.state.email== eml && this.state.password==paswd){
            this.props.history.push('/dashboard');
        }else{
            this.setState({loginError: true})
        }
        
        event.preventDefault();
    }
    // https://themewagon.com/themes/free-bootstrap-5-admin-dashboard-template-darkpan/ demo theme
    render(){
        return(
            <>
            <div className="container-fluid position-relative d-flex p-0">
        

        <div className="container-fluid">
            <form onSubmit={this.login}>
            <div className="row h-100 align-items-center justify-content-center" >
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <a href="index.html" className="">
                                <h3 className="text-primary"><i className="fa fa-user-edit me-2"></i>Metarealm</h3>
                            </a>
                            <h3>Sign In</h3>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control" id="floatingInput"
                             placeholder="name@example.com" 
                             value={this.state.email}
                             onChange={(e)=>{this.setState({email:e.target.value})}}
                             />
                            <label >Email address</label>
                        </div>
                        <div className="form-floating mb-4">
                            <input type="password" className="form-control" id="floatingPassword" 
                            placeholder="Password"
                            value={this.state.password}
                            onChange={(e)=>{this.setState({password: e.target.value})}}
                             />
                            <label >Password</label>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label">Check me out</label>
                            </div>
                            <a href="">Forgot Password</a>
                        </div>
                        <button type="submit" className="btn btn-primary py-3 w-100 mb-4">Sign In</button>
                        {this.state.loginError && this.state.loginError==true &&
                        <p className="text-primary">Enter valid email and password !!</p>
                        }
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
            </>
        )
    }
}
export default Login;