import React,{ Component} from "react";
import '../css/fontawesome.min.css';
import '../css/animate.css';
import '../css/aos.css';
import '../css/magnific-popup.css';
import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/meanmenu.min.css';
import '../css/bootstrap.min.css';
import '../css/style.css';

class Home extends Component{
    constructor(props){
        super(props);
        this.state ={
            name:'',
            email:'',
            subject:'',
            message:'',
            successSend:false,
            formSubmit:false
        }
        this.sendMessage = this.sendMessage.bind(this);
    }
    sendMessage(event){
        event.preventDefault();
        this.setState({formSubmit: true});
        fetch(`http://52.60.64.107/api/metarealm/send-message.php?name=${this.state.name}&email=${this.state.email}&subj=${this.state.subject}&message=${this.state.message}`)
        .then(response=>response.json())
        .then(data=>{
            this.setState({formSubmit: false});
            this.setState({name:''});
            this.setState({email:''});
            this.setState({subject:''});
            this.setState({message:''});
            this.setState({successSend:true});
            setTimeout(() => {
                this.setState({successSend:false});
            }, 1500);
        })
    }
    render() {
        return (
            <>
            <div  class="home-two">
            
            <header class="header-two">
    <div class="main-menu">
        <div class="container-fluid pl-60 pr-60"> 
            <div class="row align-items-center">
                <div class="col-md-4 col-lg-2 col-xl-2 d-flex">
                    <a class="navbar-brand logo" href="/">
                        <img src="assets/img/meta_realm.png" style={{height:'70px',background:'white',borderRadius:'13px'}} alt="vrlanding" />
                    </a>
                </div>
                <div class="col-xl-6 col-lg-7 pr-0 col-md-6 offset-xl-2 offset-lg-1 offset-md-1 d-none d-lg-block text-lg-right">
                    <nav id="responsive-menu" class="menu-style-two">
                        <ul class="menu-items">
                            <li>
                                <a href="/">Home </a>
                            </li>
                            <li><a href="#about">about</a></li>
                            <li><a href="#solutions">Solutions</a></li>
                            <li><a href="#contact">Contact</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-2 col-xl-2 col-md-2 d-none d-lg-inline-block">
                    <div class="get-started-btn no-fill float-right">
                        <a href="/get-quote">Get a Quote</a>
                    </div>
                </div>
                <div class="col-12">
                    <div class="responsive-menu"></div>
                </div>
            </div>
        </div>
    </div>
</header><header class="header-two">
            <div class="main-menu">
                <div class="container-fluid pl-60 pr-60">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-lg-2 col-xl-2 d-flex">
                            <a class="navbar-brand logo" href="/">
                                <img src="assets/img/meta_realm.png" style={{height:'70px'}} alt="vrlanding" />
                            </a>
                        </div>
                        <div class="col-xl-6 col-lg-7 pr-0 col-md-6 offset-xl-2 offset-lg-1 offset-md-1 d-none d-lg-block text-lg-right">
                            <nav id="responsive-menu" class="menu-style-two">
                                <ul class="menu-items">
                                    <li>
                                        <a href="/">Home </a>
                                        
                                    </li>
                                    <li><a href="#about">about</a></li>
                                    <li><a href="#solutions">Solutions</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-md-2 d-none d-lg-inline-block">
                            <div class="get-started-btn no-fill float-right">
                                <a href="/get-quote">Get a Quote</a>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="responsive-menu"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="hero-slider-wrap hero-two">
            <div class="single-hero-slide">
                <div class="slide-bg" ></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-7 col-lg-8 offset-xl-5 offset-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="hero-slide-content">
                                <h1 class="heroa1">Exploring the world’s of virtual and augmented reality.</h1>
                                <p class="heroa1">The METAREALM AGENCY provides a talented team design studio for AR and VR projects. Create your own metaverse, digital community or virtually transform your 
e-commerce, office, retail or events into an immersive experience.</p>
                                <a href="#" class="slide-btn fill slide-btn-2 heroa2">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="about-wrap section-padding" id="about">
            <div class="about-shape-wrap">
                <img src="assets/img/shape/1.png" class="shape shape1" alt="" />
                <img src="assets/img/shape/2.png" class="shape shape2" alt="" />
                <img src="assets/img/shape/3.png" class="shape shape3" alt="" />
                <img src="assets/img/shape/4.png" class="shape shape4" alt="" />
                <img src="assets/img/shape/5.png" class="shape shape5" alt="" />
                <img src="assets/img/shape/6.png" class="shape shape6" alt="" />
                <img src="assets/img/shape/7.png" class="shape shape7" alt="" />
                <img src="assets/img/shape/circle.png" class="shape circle" alt="" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-12">
                        <div class="about-content">
                            <h2>
                                <span>Virtual Reality</span> <br />
                                The future is immersive
                            </h2>
                            <p>Heralded as the next big step in computing, Virtual Reality (VR) was slow to gain traction in previous years. But as headsets become more accessible and affordable, the technology is finally growing up.</p>
                            <p>
                                While content creation for household entertainment is kicking into gear, many professional sectors are beginning to see the potential of VR applications as well. Immersive gaming and VR movie experiences are
                                set to disrupt the world of entertainment.
                            </p>
                            <div class="about-content-pattern" data-background="assets/img/about-content-illustration.png"></div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-12 mt-5 mt-lg-0">
                        <div class="vr-video-wrap bg-cover" data-background="assets/img/augment-realty_02.jpg"></div>
                    </div>
                </div>
            </div>

            <div class="features-section-wrap mt-50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="features-icon icon1">
                                    <img src="assets/img/icons/preferences.png" alt="" />
                                </div>
                                <h4>Unique User Experience</h4>
                                <p>With it's unique high graphic quality and user friendly interfaces, Pandora provides best user experience for mobile vr applications.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item pl-25">
                                <div class="features-icon icon2">
                                    <img src="assets/img/icons/speedometer.png" alt="" />
                                </div>
                                <h4>Great Functionality</h4>
                                <p>Switch between exterior view, interior view, different floors and amenity areas. See your project in Daylight or Night.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item pl-50">
                                <div class="features-icon icon3">
                                    <img src="assets/img/icons/controller.png" alt="" />
                                </div>
                                <h4>Control Comes Natuarally</h4>
                                <p>Enabling brands to deliver more value to their audiences and create much more impactful interactions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="services-wrap services-one section-padding" id="solutions">
            <div class="service-right-bg d-none d-xl-block" data-background="assets/img/service_right_bg.jpg"></div>

            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-md-10 col-12">
                        <div class="service-head-content pr-40 mb-25">
                            <br />
                            <span class="tag-title">Services</span>
                            <h2 style={{color:'black'}}>Meta Realm provides various virtual reality solution services to clients</h2>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-xl-5 col-lg-6 col-12">
                        <div class="single-service-item">
                            <div class="service-icon">
                                <img src="assets/img/icons/product-development.png" alt="" />
                            </div>
                            <div class="service-info">
                                <h4>Product Development</h4>
                                <p>With it's unique high graphic quality and user friendly interfaces, Pandora provides best user experience for mobile vr applications.</p>
                            </div>
                        </div>
                        <div class="single-service-item">
                            <div class="service-icon">
                                <img src="assets/img/icons/virtual-tour.png" alt="" />
                            </div>
                            <div class="service-info">
                                <h4>Virtual Tours</h4>
                                <p>from real estate to building sites and tourism offices, VR enables virtual tours, allowing companies to point out all the best features.</p>
                            </div>
                        </div>
                        <div class="single-service-item">
                            <div class="service-icon">
                                <img src="assets/img/icons/collab.png" alt="" />
                            </div>
                            <div class="service-info">
                                <h4>Collaboration</h4>
                                <p>virtual workspaces are an engaging, immersive and low-cost solution to holding meetings with people. It act as the collaborative hub.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-12">
                        <div class="single-service-item">
                            <div class="service-icon">
                                <img src="assets/img/icons/training.png" alt="" />
                            </div>
                            <div class="service-info">
                                <h4>Data visualization</h4>
                                <p>visualizing big data in virtual environments makes data even more engaging and actionable, enabling a whole new level of decision-making.</p>
                            </div>
                        </div>
                        <div class="single-service-item">
                            <div class="service-icon">
                                <img src="assets/img/icons/data-visual.png" alt="" />
                            </div>
                            <div class="service-info">
                                <h4>Data visualization</h4>
                                <p>virtual reality offers new ways to convey knowledge and allows employers to test their workers in realistic environments without the risks.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-shape-wrap">
                <img src="assets/img/shape/2.png" class="shape s2" alt="" />
                <img src="assets/img/shape/3.png" class="shape s3" alt="" />
                <img src="assets/img/shape/4.png" class="shape s4" alt="" />
                <img src="assets/img/shape/5.png" class="shape s5" alt="" />
                <img src="assets/img/shape/6.png" class="shape s6" alt="" />
                <img src="assets/img/shape/7.png" class="shape s7" alt="" />
            </div>
        </section>
        <section class="content-block-wrap section-padding" >
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-0 col-md-12 col-12">
                <div class="vr-content" >
                    <div class="vr-carousel">
                        <div class="single-vr-carousel" ></div>
                        <div class="single-vr-carousel"></div>
                        <div class="single-vr-carousel" ></div>
                        <div class="single-vr-carousel"></div>
                        <div class="single-vr-carousel"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-0 col-md-12 col-12 mt-5 mt-xl-0">
                <div class="content-wrap">
                    <h2 class="title" style={{color:'#000'}}>Why is it worthwhile to be present in virtual reality</h2>
                    <p>Heralded as the next big step in computing, Virtual Reality (VR) was slow to gain traction in previous years. But as headsets become more accessible and affordable, the technology is finally growing up.</p>
                    <ul class="mb-40">
                        <li>Status of innovator in the sector</li>
                        <li>Obtaining competitive advantage</li>
                        <li>Increasing sales</li>
                        <li>Involving customers in the purchase process</li>
                    </ul>
                    <a href="/get-quote" class="theme-btn">Get a free Quote</a>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<section class="faq-wrap-contact-us section-padding">
    <div class="faq-contact-shape">
        <img src="assets/img/shape/pinkstar.png" class="shape faqs1" alt="" />
        <img src="assets/img/shape/greenstar.png" class="shape faqs2" alt="" />
        <img src="assets/img/shape/fpink.png" class="shape faqs3" alt="" />
        <img src="assets/img/shape/pestar.png" class="shape faqs4" alt="" />
    </div>
    <div class="contact-us pt-150" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                    <div class="section-title text-center text-white">
                        <h2>Start your Virtual Reality journey today</h2>
                        <p>We are here to make your experience better. If you have any questions about what we offer, our perspective, or any VR topic in general – contact us using the methods above or form below.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                    <div class="contact-form mt-50">
                        <form id="contact-form" onSubmit={this.sendMessage}>
                            <div class="single-input">
                                <input type="text" name="name" id="name" placeholder="Your name*"
                                value={this.state.name}
                                onChange={(e)=>{this.setState({name:e.target.value})}}
                                />
                            </div>
                            <div class="single-input">
                                <input type="text" name="email" id="email" placeholder="Your email*"
                                value={this.state.email}
                                onChange={(e)=>{this.setState({email:e.target.value})}} />
                            </div>
                            <div class="single-input">
                                <input type="text" name="subject" id="subject" placeholder="Subject" 
                                value={this.state.subject}
                                onChange={(e)=>{this.setState({subject:e.target.value})}}/>
                            </div>
                            <div class="single-input">
                                <textarea name="message" id="message" placeholder="Your message" 
                                value={this.state.message}
                                onChange={(e)=>{this.setState({message:e.target.value})}}></textarea>
                            </div>
                            <div class="form-btn text-center">
                                <button type="submit" class=" theme-btn bck-red">Submit
                                {this.state.formSubmit && this.state.formSubmit==true &&
                                <>
                                <i className="fa fa-circle-o-notch fa-spin" style={{marginLeft:'10px'}}>&#xf1ce;</i>
                                </>}
                                </button>
                                {this.state.successSend && this.state.successSend==true &&
                                <span class="d-block">We'll get back to you in 1-2 business days.</span>}
                                <br />
                                <br />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="footer-wrap footer-one">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="footer-info">
                    <div class="footer-logo">
                        <a href="index.html">
                            <img src="assets/img/meta_realm.png" alt="VRLanding" 
                            style={{height:'70px'}} />
                        </a>
                    </div>
                    <div class="copyright-text">
                        <p>© 2022 Meta Realm. All rights reserved.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-8 col-12">
                <div class="footer-menu">
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#solutions">Solutions</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-12">
                <div class="social-profile-links">
                    {/* <a href="#"><i class="fab fa-facebook-f"></i></a> */}
                    <a href="https://twitter.com/MetarealmA" target="_blank"><i class="fab fa-twitter"></i></a>
                    <a href="Instagram:https://www.instagram.com/metarealm_agency/"  target="_blank">
                        <i class="fab fa-instagram"></i></a>
                    {/* <a href="#"><i class="fab fa-youtube"></i></a>
                    <a href="#"><i class="fab fa-vimeo-v"></i></a> */}
                </div>
            </div>
        </div>
    </div>
</footer>

   
            </div>
            </>
        )
        
        ;
      }

}
export default Home;