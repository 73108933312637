import React, { Component } from "react"; 
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state ={

        }
    }
    render(){
        return(
            <>
            <div className="container-fluid position-relative d-flex p-0">
                <div className="sidebar pe-4 pb-3">
                    <Sidebar />
                </div>
                <div className="content">
                    <Topbar />
                    <div className="container-fluid pt-4 px-4">
                        <div className="row vh-100 bg-secondary rounded align-items-center justify-content-center mx-0">
                            <div className="col-md-6 text-center">
                                <h3>This is blank page</h3>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-4 px-4">
                        <div className="bg-secondary rounded-top p-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 text-center text-sm-start">
                                    &copy; <a href="#">Metarealm</a>, All Right Reserved. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
            </div>
            </>
        )
    }
}
export default Dashboard;
