import React,{ Component } from "react";
import '../css/style.css';

class GetQuote extends Component{
    constructor(props){
        super(props);
        this.state = { 
            email:'',
            name:'',
            whatNature:'',
            concept:false,
            design:false,
            transfomation:false,
            output:false,

            engagement:'',
            intended:'',
            consider:'',
            branding:'',
            timeexp:'',
            allocated:'',
            greater:''

         };
         this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state)
        fetch(`http://52.60.64.107/api/metarealm/getquote.php?whatNature=`+this.state.whatNature+`&concept=`+this.state.concept
        +`&design=`+this.state.design+`&transfomation=`+this.state.transfomation+`&output=`+this.state.output
        +`&engagement=`+this.state.engagement+`&intended=`+this.state.intended+`&consider=`+this.state.consider
        +`&branding=`+this.state.branding+`&timeexp=`+this.state.timeexp+`&allocated=`+this.state.allocated
        +`&greater=`+this.state.greater+`&email=`+this.state.email+`&name=`+this.state.name
        )
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({email: ''});
                this.setState({name: ''});
                this.setState({whatNature: ''});
                this.setState({concept: false});
                this.setState({design: false});
                this.setState({transfomation: false});
                this.setState({output: false});
                this.setState({engagement: ''});
                this.setState({intended: ''});
                this.setState({consider: ''});
                this.setState({branding: ''});
                this.setState({timeexp: ''});
                this.setState({allocated: ''});
                this.setState({greater: ''});
            });
    }

    render() {
        return(
            <>
             <div  class="home-two">
            
            <header class="header-two">
    <div class="main-menu">
        <div class="container-fluid pl-60 pr-60"> 
            <div class="row align-items-center">
                {/* <div class="col-md-4 col-lg-2 col-xl-2 d-flex">
                    <a class="navbar-brand logo" href="/">
                        <img src="assets/img/meta-realm_(final)2.png" alt="vrlanding" />
                    </a>
                </div> */}
                {/* <div class="col-xl-6 col-lg-7 pr-0 col-md-6 offset-xl-2 offset-lg-1 offset-md-1 d-none d-lg-block text-lg-right">
                    <nav id="responsive-menu" class="menu-style-two">
                        <ul class="menu-items">
                            <li>
                                <a href="/">Home 1</a>
                            </li>
                            <li><a href="#about">about</a></li>
                            <li><a href="#solutions">Solutions</a></li>
                            <li><a href="#case">Case Studies</a></li>
                            <li><a href="#contact">Contact</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-2 col-xl-2 col-md-2 d-none d-lg-inline-block">
                    <div class="get-started-btn no-fill float-right">
                        <a href="/get-quote">Get a Quote</a>
                    </div>
                </div> */}
                <div class="col-12">
                    <div class="responsive-menu"></div>
                </div>
            </div>
        </div>
    </div>
</header><header class="header-two">
            <div class="main-menu">
                <div class="container-fluid pl-60 pr-60">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-lg-2 col-xl-2 d-flex">
                            <a class="navbar-brand logo" href="/">
                                <img src="assets/img/meta_realm.png" alt="vrlanding" />
                            </a>
                        </div>
                        <div class="col-xl-6 col-lg-7 pr-0 col-md-6 offset-xl-2 offset-lg-1 offset-md-1 d-none d-lg-block text-lg-right">
                            <nav id="responsive-menu" class="menu-style-two">
                                <ul class="menu-items">
                                    <li>
                                        <a href="/">Home </a>
                                        
                                    </li>
                                    <li><a href="#about">about</a></li>
                                    <li><a href="#solutions">Solutions</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-md-2 d-none d-lg-inline-block">
                            <div class="get-started-btn no-fill float-right">
                                <a href="/get-quote">Get a Quote</a>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="responsive-menu"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="hero-slider-wrap hero-two">
            <div class="single-hero-slide">
                <div class="slide-bg" ></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-7 col-lg-8 offset-xl-5 offset-lg-4 col-md-12 col-sm-12 col-12 mrg-top-100">
                            <div class="hero-slide-content">
                                <div className="qstion-box">
                                    <br />
                                <form onSubmit={this.handleSubmit}>
                                <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Enter your email id
                                            </label>
                                        <input type="text" className="form-control"
                                        value={this.state.email}
                                        onChange={(e)=>{ this.setState({email: e.target.value })} }
                                         />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                        Your Name
                                            </label>
                                        <input type="text" className="form-control"
                                        value={this.state.name}
                                        onChange={(e)=>{ this.setState({name: e.target.value })} }
                                         />
                                    </div>

                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                             What is the nature of the project or item(s) you want to bring into the virtual realm ?</label>
                                        <input type="text" className="form-control"
                                        value={this.state.whatNature}
                                        onChange={(e)=>{ this.setState({whatNature: e.target.value })} }
                                         />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Do you require any initial review ?</label><br />
                                        
                                        <label>&nbsp;<input type="checkbox" 
                                        defaultChecked={this.state.concept}
                                        onChange={(e)=>{this.setState({concept:e.target.value})}} /> Concept  </label> &nbsp;

                                        <label><input type="checkbox" 
                                        defaultChecked={this.state.design}
                                        onChange={(e)=>{this.setState({design:e.target.value})}} /> Design </label> &nbsp;

                                        <label><input type="checkbox"
                                        defaultChecked={this.state.transfomation}
                                        onChange={(e)=>{this.setState({transfomation:e.target.value})}} /> Transformation layout </label> &nbsp;

                                        <label><input type="checkbox"
                                        defaultChecked={this.state.output}
                                        onChange={(e)=>{this.setState({output:e.target.value})}} /> Production output </label> &nbsp;
                                        <label><input type="checkbox" /> Hosted environment</label> &nbsp;
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Where or What is the targeted medium of engagement ?</label>
                                        <input type="text" className="form-control"
                                        value={this.state.engagement}
                                        onChange={(e)=>{this.setState({engagement:e.target.value})}}
                                         />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Who is the intended audience ?</label>
                                        <input type="text" className="form-control"
                                        value={this.state.intended}
                                        onChange={(e)=>{this.setState({intended:e.target.value})}}
                                         />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Is there any 3rd party partnerships to consider ?</label>
                                        <input type="text" className="form-control" 
                                        value={this.state.consider}
                                        onChange={(e)=>{this.setState({consider: e.target.value})}}
                                        />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            How about branding limitations, policies or restrictions you can let us know about ?</label>
                                        <input type="text" className="form-control" 
                                        value={this.state.branding}
                                        onChange={(e)=>{ this.setState({branding: e.target.value})}}
                                         />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Timeline expectations ?</label>
                                        <input type="text" className="form-control" 
                                        value={this.state.timeexp}
                                        onChange={(e)=>{this.setState({timeexp:e.target.value})}}
                                        />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Are you ok to discuss or provide a budget allocated ?</label>
                                        <input type="text" className="form-control" 
                                        value={this.state.allocated}
                                        onChange={(e)=>{this.setState({allocated:e.target.value})}}
                                        />
                                    </div>
                                    <div className="f-group">
                                        <label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg> &nbsp;
                                            Let us know anything else that might lead to a greater success ?</label>
                                        <input type="text" className="form-control"
                                        value={this.state.greater}
                                        onChange={(e)=>{this.setState({greater:e.target.value})}} />
                                    </div>
                                    <div className="f-group">
                                    <button type="submit" class="submit-btn theme-btn">Submit</button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       
<footer class="footer-wrap footer-one">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="footer-info">
                    <div class="footer-logo">
                        <a href="index.html">
                            <img src="assets/img/meta-realm_(final)_3.png" alt="VRLanding" />
                        </a>
                    </div>
                    <div class="copyright-text">
                        <p>© 2022 Meta Realm. All rights reserved.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-8 col-12">
                <div class="footer-menu">
                    <ul>
                        <li><a href="#">Home </a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Solutions</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-12">
                <div class="social-profile-links">
                <a href="https://twitter.com/MetarealmA" target="_blank"><i class="fab fa-twitter"></i></a>
                    <a href="Instagram:https://www.instagram.com/metarealm_agency/"  target="_blank">
                        <i class="fab fa-instagram"></i></a>
                </div>
            </div>
        </div>
    </div>
</footer>

   
            </div>
            </>
        );
      }
}
export default GetQuote