import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import axios from 'axios';

class Product extends Component{
    constructor(props){
        super(props);
        this.state ={
            categoryList: [],
            productList: [],
            productCat:'',
            productName:'',
            productPrice: 0,
            selectedFile: null,
            selectedFile2: null,
            prodDetails:'',
            catError:false,
            nameError:false,
            priceError:false,
            addSuccess:false,
        }
        this.addProducr = this.addProducr.bind(this);
        this.getAllProduct = this.getAllProduct.bind(this);
        this.delProd = this.delProd.bind(this);
        //const formData = new FormData();
    }
    componentDidMount(){
        fetch(`http://52.60.64.107/api/metarealm/allcategory.php`)
        .then(response =>response.json())
        .then(data=>{
            this.setState({categoryList:data.result});
        })
        this.getAllProduct();
    }
    getAllProduct(){
        fetch(`http://52.60.64.107/api/metarealm/product-list.php`)
        .then(response =>response.json())
        .then(data=>{
            this.setState({productList:data.result});
        })
    }
    // delProd
    delProd(cat){
        let text = "Are you sure!!";
        if (window.confirm(text) == true) {
            fetch(`http://52.60.64.107/api/metarealm/product-del.php?id=${cat.id}`)
            .then(response =>response.json())
            .then(data=>{
                this.getAllProduct();
            })
        }
       
    }
    onFileChange = event => {
        this.setState({ selectedFile: event.target.files});
      };
      onFileChange2 = event => {
        this.setState({ selectedFile2: event.target.files});
      };
    addProducr(event){
        event.preventDefault();
        const formData = new FormData();
        // if(this.state.productCat=='') return this.setState({catError:true});
        // if(this.state.productName=='') return this.setState({nameError:true});
        for (const key of Object.keys(this.state.selectedFile)) {
            formData.append('productImage_'+key, this.state.selectedFile[key])
        }
        for (const key of Object.keys(this.state.selectedFile2)) {
            formData.append('akImage_'+key, this.state.selectedFile2[key])
        }
        
          let ddt ={prodDetails:this.state.prodDetails, productCat: this.state.productCat, 
            productName: this.state.productName,productPrice: this.state.productPrice
            }
          formData.append(
            "productData",
            JSON.stringify(ddt)
          );
          formData.append(
            "categoryId",
            this.state.productCat
          );
        axios.post("http://52.60.64.107/api/metarealm/add-product.php", formData);
        this.setState({productCat:''});
        this.setState({productName:''});
        this.setState({productPrice:''});
        this.setState({prodDetails:''});
        this.setState({addSuccess: true});
        setTimeout(() => {
            this.setState({addSuccess: false});
        }, 1500);

        
        //.then()
    
    }
    render(){
        return(
            <>
            <div className="container-fluid position-relative d-flex p-0">
                <div className="sidebar pe-4 pb-3">
                    <Sidebar />
                </div>
                <div className="content">
                    <Topbar />
                    <div className="container-fluid pt-4 px-4">
                        <div className="row vh-100 bg-secondary rounded  mx-0">
                        <div className="col-sm-12 col-xl-6">
                            <div className="bg-secondary rounded h-100 p-4">
                                <h6 className="mb-4">Add product</h6>
                                <form onSubmit={this.addProducr} enctype="multipart/form-data">
                                <div className="mb-3">
                                        <label className="form-label">Select category</label>
                                        <select className="form-control" value={this.state.productCat}
                                        onChange={(e)=>{this.setState({productCat:e.target.value})} }
                                        >
                                            <option value="">Select</option>
                                            {this.state.categoryList && this.state.categoryList.length >0 &&
                                            <>
                                            {this.state.categoryList.map((category,indx)=>(
                                                <option key={indx} value={category.id}>{category.name}</option>
                                            )) }
                                            </>
                                            }
                                        </select>
                                        {this.state.addError ==true &&
                                        <p className="text-primary">Please enter category !!</p> }
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Product name</label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                        value={this.state.productName}
                                        onChange={(e)=>{this.setState({productName:e.target.value})}}
                                        />
                                        {this.state.addError ==true &&
                                        <p className="text-primary">Please enter product name !!</p> }
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Product price $</label>
                                        <input type="number" className="form-control" 
                                        value={this.state.productPrice}
                                        onChange={(e)=>{this.setState({productPrice:e.target.value})}}
                                        />
                                        {this.state.addError ==true &&
                                        <p className="text-primary">Please enter product price !!</p> }
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Product Image</label>
                                        <input type="file" className="form-control" 
                                        onChange={this.onFileChange}
                                        multiple
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <textarea className="form-control" placeholder="Description" rows="4"
                                        value={this.state.prodDetails}
                                        onChange={(e)=>{this.setState({prodDetails:e.target.value})}}
                                        ></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Ar files</label>
                                        <input type="file" className="form-control" 
                                        onChange={this.onFileChange2}
                                        multiple
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary pull-right">Add</button> &nbsp;&nbsp;&nbsp;
                                    {this.state.addSuccess ==true &&
                                    <span className="text-primary">Product saved successfully !!</span>}
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-6">
                                <div className="table-responsive bg-secondary rounded h-100 p-4">
                                    <h6 className="md-4">Product list</h6>
                                {this.state.productList && this.state.productList.length >0 &&
                                <table className="table table-bordered">
                                    <thead>
                                        <th>Sl.</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                    {this.state.productList.map((cat,index)=>(
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{cat.productData.productName} </td>
                                            <td> $ {cat.productData.productPrice} </td>
                                            <td>
                                                <button type="button" className="btn btn-danger" onClick={()=>this.delProd(cat) } >Del</button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-4 px-4">
                        <div className="bg-secondary rounded-top p-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 text-center text-sm-start">
                                    &copy; <a href="#">Metarealm</a>, All Right Reserved. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
export default Product;