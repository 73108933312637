import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

class Quotelist extends Component{
    constructor(props){
        super(props);
        this.state ={
            quotelist:[]
        };
    }
    componentDidMount(){
        fetch(`http://52.60.64.107/api/metarealm/getquote-list.php`)
        .then(response=>response.json())
        .then(data=>{
            this.setState({quotelist: data.result});
        } )
    }
    render(){
        return(
            <>
            <div className="container-fluid position-relative d-flex p-0">
                <div className="sidebar pe-4 pb-3">
                    <Sidebar />
                </div>
                <div className="content">
                    <Topbar />
                    <div className="container-fluid pt-4 px-4">
                        <div className="row vh-100 bg-secondary rounded  mx-0">
                        <div className="col-sm-12 col-xl-12">
                        <div className="bg-secondary rounded h-100 p-4">
                            <h6 className="mb-4">Quote list</h6>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Email</th>
                                        <th>Ans 1</th>
                                        <th>Ans 2</th>
                                        <th>Ans 3</th>
                                        <th>Ans 4</th>
                                        <th>Ans 5</th>
                                        <th>Ans 6</th>
                                    </tr>
                                </thead>
                                { this.state.quotelist && this.state.quotelist.length >0 &&
                                    <tbody>
                                        {this.state.quotelist.map((list,inds)=>(
                                        <tr key={inds}>
                                            <td>{inds+1} </td>
                                            <td>{list.data.email} </td>
                                            <td>{list.data.whatNature} </td>
                                            <td>{list.data.engagement} </td>
                                            <td>{list.data.intended} </td>
                                            <td>{list.data.consider} </td>
                                            <td>{list.data.branding} </td>
                                            <td>{list.data.timeexp} </td>
                                        </tr>
                                        )) }
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-4 px-4">
                        <div className="bg-secondary rounded-top p-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 text-center text-sm-start">
                                    &copy; <a href="#">Metarealm</a>, All Right Reserved. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
            </div>
            </>
        );
    }
}

export default Quotelist;