import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

class Category extends Component{
    constructor(props){
        super(props);
        this.state ={
            catName:'',
            catDetails:'',
            catList:[],
            addError:false,
            addSuccess:false,
        }
        this.addCategory    = this.addCategory.bind(this);
        this.getAllCategory = this.getAllCategory.bind(this);
        this.delCat = this.delCat.bind(this);
    }
    getAllCategory(){
        fetch(`http://52.60.64.107/api/metarealm/category-list.php`)
        .then(response =>response.json())
        .then(data=>{
            this.setState({catList: data.result});
        })
    }
    delCat(cat){
        let text = "Are you sure!!";
        if (window.confirm(text) == true) {
            fetch(`http://52.60.64.107/api/metarealm/category-del.php?id=${cat.id}`)
            .then(response =>response.json())
            .then(data=>{
                this.getAllCategory();
            })
        }
    }
    componentDidMount(){
        this.getAllCategory();
    }

    addCategory(event){
        event.preventDefault();
        console.log(this.state)

        if(this.state.catName==''){
            this.setState({addError:true});
            this.setState({addSuccess:false});
            return;
        }
        
        fetch(`http://52.60.64.107/api/metarealm/add-category.php?name=${this.state.catName}&description=${this.state.catDetails}`)
        .then(response => response.json())
        .then(data =>{
            console.log(data);
            this.setState({addSuccess:true});
            this.setState({catName:''});
            this.setState({catDetails:''});
            this.setState({addError:false});
        })
        
    }
    render(){
        return(
            <>
            <div className="container-fluid position-relative d-flex p-0">
                <div className="sidebar pe-4 pb-3">
                    <Sidebar />
                </div>
                <div className="content">
                    <Topbar />
                    <div className="container-fluid pt-4 px-4">
                        <div className="row vh-100 bg-secondary rounded  mx-0">
                            <div className="col-sm-12 col-xl-6">
                                <div className="bg-secondary rounded h-100 p-4">
                                    <h6 className="mb-4">Add category</h6>
                                    <form onSubmit={this.addCategory}>
                                        <div className="mb-3">
                                            <label className="form-label">Category name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                            value={this.state.catName}
                                            onChange={(e)=>{this.setState({catName:e.target.value})}}
                                            />
                                            {this.state.addError ==true &&
                                            <p className="text-primary">Please enter category name !!</p> }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Description</label>
                                            <textarea className="form-control" placeholder="Description" rows="5"
                                            value={this.state.catDetails}
                                            onChange={(e)=>{this.setState({catDetails:e.target.value})}}
                                            ></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-primary pull-right">Add</button> &nbsp;&nbsp;&nbsp;
                                        {this.state.addSuccess ==true &&
                                        <span className="text-primary">Category saved successfully !!</span>}
                                    </form>
                                </div>
                            </div>
                            <div className="col-sm-12 col-xl-6">
                                <div className="table-responsive bg-secondary rounded h-100 p-4">
                                    <h6 className="md-4">Category list</h6>
                                {this.state.catList && this.state.catList.length >0 &&
                                <table className="table table-bordered">
                                    <thead>
                                        <th>Sl.</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                    {this.state.catList.map((cat,index)=>(
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{cat.name} </td>
                                            <td>
                                                <button type="button" className="btn btn-danger" onClick={()=>this.delCat(cat) } >Del</button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-4 px-4">
                        <div className="bg-secondary rounded-top p-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 text-center text-sm-start">
                                    &copy; <a href="#">Metarealm</a>, All Right Reserved. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            </>
        );
    }
}
export default Category;