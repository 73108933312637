import React,{ Component } from "react";

class Sidebar extends Component{
    constructor(props){
        super(props);
        this.state ={}
    }
    render(){
        return(
            <nav className="navbar bg-secondary navbar-dark">
                <a href="index.html" className="navbar-brand mx-4 mb-3">
                    <h3 className="text-primary"><i className="fa fa-user-edit me-2"></i>Metarealm</h3>
                </a>
                <div className="d-flex align-items-center ms-4 mb-4">
                    <div className="position-relative">
                        
                        <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
                    </div>
                    <div className="ms-3">
                        <h6 className="mb-0"></h6>
                        <span>Admin</span>
                    </div>
                </div>
                <div className="navbar-nav w-100">
                    <a href="/dashboard" className="nav-item nav-link active"><i className="fa fa-tachometer-alt me-2"></i>Dashboard</a>
                    <a href="/category" className="nav-item nav-link"><i className="fa fa-th me-2"></i>Category</a>
                    <a href="/product" className="nav-item nav-link"><i className="fa fa-th me-2"></i>Product</a>
                    <a href="/quote-list" className="nav-item nav-link"><i className="fa fa-th me-2"></i>Quote list</a>
                    
                </div>
            </nav>
        )
    }
}
export default Sidebar;