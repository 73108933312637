import React,{ Component} from "react";
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Home from './components/Home';
import GetQuote from './components/GetQuote';
import Login from "./components/Login";
import Dashboard from "./components/admin/Dashboard";
import Category from "./components/admin/Category";
import Product from "./components/admin/Product";
import Quotelist from "./components/admin/Qutotelist";

class App extends Component {

  render() {
    return (
      <>
      <Router>
      <div id="container">
        <Switch>
          <Route exact path="/" component={Home } />
          <Route exact path="/get-quote" component={GetQuote } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/dashboard" component={ Dashboard } />
          <Route exact path="/category" component={ Category } />
          <Route exact path="/product" component={ Product } />
          <Route exact path="/quote-list" component={ Quotelist } />
        
        </Switch>
      </div>
      </Router>
      </>
    );
  }
}

export default App;

